<template>
  <b-card
    class="financeresourcerelation-edit-wrapper"
  >
    <ValidationObserver ref="observer">
    <!-- form -->
    <b-form id="financeresourcerelationForm" class="edit-form mt-2">
      <b-row>
        <!--收款单位-->
        <b-col
                cols="12"
                md="3"
                class="mb-2"
        >
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="收款单位"
                  label-for="mainCompanyName"
                  label-size="sm"
                  class="mb-1 required"
          >
            <ValidationProvider rules="required" name="收款单位" #default="{ errors }" >
              <v-select
                      id="mainCompanyName"
                      :options="mainCompanyArr"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="mainCompanyName"
                      @input="setMainCompanyName"
                      placeholder="请选择主体名称"
                      :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>

        <b-col
                cols="12"
                md="4"
                class="mb-2"
        >
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="收款账号"
                  label-for="accountNo"
                  label-size="sm"
                  class="mb-1 required"
          >
            <ValidationProvider rules="required" name="收款账号" #default="{ errors }" >
              <v-select
                      id="accountNo"
                      :options="accountNoArr"
                      :clearable="true"
                      class="select-size-sm"
                      v-model="accountNo"
                      @input="changeSelect('account',$event)"
                      placeholder="请选择收款账号"
                      :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>

        <b-col
                cols="12"
                md="4"
                class="mb-2"
        >
          <ValidationProvider rules="required" name="店铺" #default="{ errors }" >
          <modal-select
                  label="店铺团队"
                  type="input"
                  v-on:change="fromChildrenAdd($event,['store_name','store_id'])"
                  :params="['store_name','store_id']"
                  :company_id="companyId"
                  modalName="店铺"
                  placeholder="点击选择店铺 "
                  v-model="storeName"
          >
          </modal-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-col>


                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </ValidationObserver>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import financeresourcerelationStore from './financeresourcerelationStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty,isNumber} from '@core/utils/filter'
import axios from "@/libs/axios";
import XyInputButton from "@/views/components/xy/XyInputButton";
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    XyInputButton,
    ModalSelect,
  },
  data() {
    return {
      id: ref(0),
      financeresourcerelation: ref({}),
      mainCompanyArr: [],
      accountNoArr:[],
      mainCompanyName: {},
      accountNo: '',
      companyId:90,
      storeName:''

    }
  },
  methods: {
    setMainCompanyName() {
      this.financeresourcerelation.company_name = this.mainCompanyName.label
      this.financeresourcerelation.company_id = this.mainCompanyName.company_id
      this.accountNo = ''
      this.accountNoArr=[]
      axios.post('/api/bankaccount/search', {
        company_id: this.financeresourcerelation.company_id
      }).then(res => {
        if (res.data.data.list.length > 0) {
          let list = res.data.data.list
         for(let i = 0 ; i < list.length ; i++){
           this.accountNoArr.push({label: list[i].account_no, value: list[i].account_id})
         }
        }
      })
      this.$forceUpdate()
    },
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('financeresourcerelation')) store.registerModule('financeresourcerelation', financeresourcerelationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('financeresourcerelation')) store.unregisterModule('financeresourcerelation')
    })

    const edit = function() {
      store.dispatch('financeresourcerelation/edit', {id: this.id}).then(res => {
        this.financeresourcerelation = res.data.data
        this.mainCompanyName = this.financeresourcerelation.company_name
        this.storeName = this.financeresourcerelation.store_name
        if(isNumber(this.financeresourcerelation.company_id)>0) {
          this.accountNo = isEmpty(this.financeresourcerelation.ext.account_no) ? "" : this.financeresourcerelation.ext.account_no
          axios.post('/api/bankaccount/search', {
            company_id: this.financeresourcerelation.company_id
          }).then(res => {
            if (res.data.data.list.length > 0) {
              let list = res.data.data.list
              for (let i = 0; i < list.length; i++) {
                this.accountNoArr.push({label: list[i].account_no, value: list[i].account_id})
              }
            }
          })
        }
        getMainCompany(this.mainCompanyArr)
      })

    }

    const view = function() {
      store.dispatch('financeresourcerelation/view', {id: this.id}).then(res => {
        this.financeresourcerelation = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = async function() {
      let success = await this.$refs.observer.validate()
      if(success){
        store.dispatch('financeresourcerelation/save', this.financeresourcerelation).then(res => {
          if(res.data.code == 0){
            toast.success('数据已保存!')
          }else {
            toast.error(res.data.data)
          }
          this.$router.push({ name: 'apps-financeresourcerelation-list'});
        })
      }
    }

    const getMainCompany = function (arr) {
      axios.get('/api/company/list', {
        params: {
          companyTypes: '2'
        }
      }).then(function (res) {
        const a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          arr.push({"label": a[i].company_name, "company_id": a[i].company_id})
        }
      }).catch(function (res) {
        alert(res)
      })
    }
    const fromChildrenAdd = function (params, modal) {
     if(modal.length>1){
        this.financeresourcerelation.store_name = params == null ? null : params[modal[0]]
        this.storeName = params == null ? null : params[modal[0]]
        this.financeresourcerelation.store_id = params == null ? null : params[modal[1]]

      }
      this.$forceUpdate()
    }

    const changeSelect = function(key,event) {
      this.financeresourcerelation.account_id = event.value
      this.accountNo = event.label
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      getMainCompany,
      fromChildrenAdd,
      changeSelect,
      isEmpty,
      isNumber
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
